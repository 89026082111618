<template>
  <div>
    <form-section :title="$t('courierSet.setData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.configurationName"
            :title="$t('courierSet.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.id"
            :title="$t('courierSet.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-field
            v-model="getConfigurationValues.courierCode"
            :title="$t('courierSet.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.service"
            :title="$t('courierSet.serviceType')"
            rules="required"
            :filed-items="services"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceInfo')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.sendingMethod"
            :title="$t('courierSet.sendingMethod')"
            rules="required"
            :filed-items="sendingMethods"
          />
        </v-col>
      </v-row>
      <v-row v-if="getConfigurationValues.sendingMethod === 'parcel_locker'">
        <v-col>
          <text-field
            v-model="getCustomAttributes.dropoffPoint"
            :title="$t('courierSet.dropoffPoint')"
            :rules="getDropoffPointRules"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.authData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.login"
            :title="$t('courierSet.login')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.password"
            :title="$t('courierSet.apiKey')"
            type="password"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.urlAddress"
            :title="$t('courierSet.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.organizationId"
            :title="'ID firmy'"
            rules="required"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { services } from '@/constants/Speditors/InPost/Services';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';
import { sendingMethods } from '@/constants/Speditors/InPost/SendingMethods';
import { mapGetters } from 'vuex';
import { TEST_INPOST_URL } from '@/constants/Speditors/testApi.js';
import { PROD_INPOST_URL } from '@/constants/Speditors/prodApi.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    SHIPMENT_METHOD_CODES,
    services,
    sendingMethods,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationValues() {
      return this.getConfigurationSet();
    },
    getCustomAttributes() {
      return this.getConfigurationSet().inPostDefaultValues.customAttributes;
    },
    getDropoffPointRules() {
      return this.getConfigurationValues.sendingMethod === 'parcel_locker' ? 'required' : '';
    },
    getUrlAddress() {
      if (process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT === 'production') {
        return `https://${PROD_INPOST_URL}`;
      }
      return `https://${TEST_INPOST_URL}`;
    },
  },
  watch: {
    'getConfigurationValues.sendingMethod': {
      handler(newValue) {
        if (newValue !== 'parcel_locker') {
          this.getConfigurationSet().inPostDefaultValues.customAttributes.dropoffPoint = '';
        }
      },
    },
  },
  mounted() {
    this.getConfigurationValues.courierCode = 'inpost';
    if (this.isNew) {
      this.getConfigurationValues.id = uuidv4();
      this.getConfigurationValues.urlAddress = this.getUrlAddress;
    }
  },
};
</script>

<style scoped></style>
